import { Button, Tooltip, ConfigProvider } from 'antd';
import { useI18n } from '@affine/i18n';
import { AppstoreAddOutlined } from '@ant-design/icons';
import { useStyle } from '@affine/core/hooks/use-antd-style';

interface DocumentTemplatesButtonProps {
  onClick?: () => void;
}
export function DocumentTemplatesButton({
  onClick,
}: DocumentTemplatesButtonProps) {
  const t = useI18n();
  const { styles } = useStyle();

  return (
    <Tooltip title={t['com.affine.collection.documentsTemplates']()}>
      <ConfigProvider
        button={{
          className: styles.linearGradientButton,
        }}
      >
        <Button
          icon={<AppstoreAddOutlined />}
          data-testid="sidebar-document-templates-button"
          type="primary"
          size="large"
          onClick={onClick}
        >
          {t['com.affine.collection.documentsTemplates']()}
        </Button>
      </ConfigProvider>
    </Tooltip>
  );
}
