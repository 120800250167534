import { apis } from '@affine/electron-api';
import { ThemeProvider as NextThemeProvider, useTheme } from 'next-themes';
import { ConfigProvider as AntdConfigProvider } from 'antd';
import type { PropsWithChildren } from 'react';
import { memo, useRef } from 'react';

const themes = ['dark', 'light'];

const DesktopThemeSync = memo(function DesktopThemeSync() {
  const { theme } = useTheme();
  const lastThemeRef = useRef(theme);
  const onceRef = useRef(false);
  if (lastThemeRef.current !== theme || !onceRef.current) {
    if (environment.isDesktop && theme) {
      apis?.ui
        .handleThemeChange(theme as 'dark' | 'light' | 'system')
        .catch(err => {
          console.error(err);
        });
    }
    lastThemeRef.current = theme;
    onceRef.current = true;
  }
  return null;
});

export const ThemeProvider = ({ children }: PropsWithChildren) => {
  const config = {
    dark: {
      token: {
        // Seed Token
        colorPrimary: '#000000',
      },
    },
    light: {
      token: {
        // Seed Token
        colorPrimary: '#62C4B8',
      },
    },
  };

  const { theme = 'light' } = useTheme();
  return (
    <AntdConfigProvider theme={config[theme]}>
      <NextThemeProvider themes={themes} enableSystem={true}>
        {children}
        <DesktopThemeSync />
      </NextThemeProvider>
    </AntdConfigProvider>
  );
};
