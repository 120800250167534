// useAxios hook

import { useState, useEffect } from 'react';
import axios from 'axios';

const DIFY_TOKEN_KEY =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiZThlODZhMTMtOTFiMC00MTBmLWFjMGUtZjQwNWYwM2MxMDYwIiwiZXhwIjoxNzI3NDA0NjczLCJpc3MiOiJTRUxGX0hPU1RFRCIsInN1YiI6IkNvbnNvbGUgQVBJIFBhc3Nwb3J0In0.LleZqtS2cMIkKXetqfclgKEHjAjzPBnyQR2DsQFyH7Y';
const DIFY_API_URL = 'https://studio.effiai.one/console/api/';

//const DIFY_USER_NAME = process.env.DIFY_USER_NAME
//const DIFY_PASSWORD = process.env.DIFY_PASSWORD

export const useToken = () => {
  let token = JSON.parse(localStorage.getItem('user'))?.token || '';
  if (!token || token === '') {
    axios
      .post(`${DIFY_API_URL}login`, {
        email: 'tranquyhiep87@gmail.com',
        password: 'effiai@2024',
        remember_me: true,
      })
      .then(({ data }) => {
        token = data.data;
        localStorage.setItem('user', JSON.stringify({ token: token }));
      });
  }
  return token;
};

export const callDifyApi = ({
  url,
  method,
  params = [],
  body = null,
  headers = null,
}) => {
  const token = useToken();
  const api = axios.create({
    baseURL: DIFY_API_URL,
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  return api[method](url, { params }, JSON.parse(headers), JSON.parse(body));
};

export const useDifyApi = ({
  url,
  method,
  params = [],
  body = null,
  headers = null,
}) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState('');
  const [loading, setloading] = useState(true);

  const fetchData = () => {
    callDifyApi({
      url,
      method,
      params,
      body,
      headers,
    })
      .then(res => {
        setResponse(res.data);
      })
      .catch(err => {
        localStorage.removeItem('user');
        window.location.reload();
        setError(err);
      })
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [method, url, body, headers]);

  return { response, error, loading };
};

export default useDifyApi;
